import React from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "../../auth/auth0-context";
import classNames from "classnames";

// import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Menu from "@material-ui/core/Menu";

import MenuItem from "@material-ui/core/MenuItem";

// @material-ui/icons
import MenuOutlinedIcon from "@material-ui/icons/MenuOutlined";
// core components
import AdminNavbarLinks from "./AdminNavbarLinks";
import Button from "../CustomButtons/Button";
import headerStyle from "../../assets/jss/material-dashboard-react/components/headerStyle";
import Avatar from "@material-ui/core/Avatar";

function Header({ ...props }: any) {
  const { isLoading, user, logout } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function makeBrand() {
    var name;
    props.routes.map((prop: any, key: any) => {
      if (prop.layout + prop.path === props.location.pathname) {
        name = props.rtlActive ? prop.rtlName : prop.name;
      }
      return null;
    });
    return name;
  }
  const { classes, color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color,
  });
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <Button color="transparent" href="#" className={classes.title}>
            {makeBrand()}
          </Button>
        </div>
        <Hidden smDown={true} implementation="css">
          <AdminNavbarLinks />
        </Hidden>
        <Hidden mdUp={true} implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          />
        </Hidden>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-label="Person"
          className={classes.buttonLink}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <Avatar className={classes.icons}>{user.name[0]}</Avatar>
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <Link className={"nav-link"} to={"/admin/profile"}>
            <MenuItem className={classes.dropDownLink} onClick={handleClose}>
              <p className={classes.dropDownText}>Profile</p>
            </MenuItem>
          </Link>
          <hr />
          <Link className={"nav-link"} to={"/"}>
            {!isLoading && user && (
              <MenuItem
                className={classes.dropDownLink}
                onClick={() => logout({ returnTo: window.location.origin })}
              >
                <p className={classes.dropDownText}> Logout</p>
              </MenuItem>
            )}
          </Link>
        </Menu>
        <Hidden mdUp={true} implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <MenuOutlinedIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

export default withStyles(headerStyle)(Header);
