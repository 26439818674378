// @material-ui/icons
import HomeIcon from "@material-ui/icons/Home";
import Person from "@material-ui/icons/Person";

// core components/views for Admin layout
import DashboardPage from "./views/Dashboard/Dashboard";
import UserProfile from "./views/UserProfile/UserProfile";

const dashboardRoutes = [
  {
    path: "/home",
    name: "Home",
    icon: HomeIcon,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/profile",
    name: "Profile",
    icon: Person,
    component: UserProfile,
    layout: "/admin",
  },
];

export default dashboardRoutes;
