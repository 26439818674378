import {
  drawerWidth,
  transition,
  container
} from '../../material-dashboard-react';
import { createStyles, Theme } from '@material-ui/core';
import background from "assets/img/blue-ciuszek_edited2.jpeg";

const appStyle = (theme: Theme) =>
  createStyles({
    wrapper: {
      position: "relative",
      top: "0",
      height: "100vh",
      backgroundColor: "white",
    },
    mainPanel: {
      [theme.breakpoints.up("md")]: {
        width: `calc(100% - ${drawerWidth}px)`,
      },
      overflow: "auto",
      position: "relative",
      float: "right",
      ...transition,
      maxHeight: "100%",
      width: "100%",
      overflowScrolling: "touch",
    },
    content: {
      marginTop: "70px",
      padding: "30px 15px",
      minHeight: "calc(90vh - 123px)",
      // backdropFilter: "blur(1.7px)",
    },
    container,
    map: {
      marginTop: "70px",
    },
    background: {
      background: `url(${background})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right",
      backgroundSize: "contain",
      position: "relative",
      height: "auto",
      backgroundAttachment: "fixed",
      backgroundClip: "content-box",
      minHeight: "100vh",
      width: "-webkit-fill-available",
      display: "flex",
      flexDirection: "column",
      webkitBoxPack: "start",
      justifyContent: "flex-start",
      [theme.breakpoints.down("xs")]: {
        backgroundImage: "none",
      },
    },
  });

export default appStyle;
