import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useAuth0 } from "./auth/auth0-context";

// core components
import Admin from "./layouts/Admin";
import "assets/css/material-dashboard-react.css?v=1.6.0";
import PageSpinner from "./components/Spinners/PageSpinner";

function App(): JSX.Element {
  const { isAuthenticated, isLoading, user, loginWithRedirect } = useAuth0();

   useEffect(() => {
     !isLoading && !user && loginWithRedirect()
     }, [isLoading, user, loginWithRedirect]);
  
  return (
    <div className="App">
      <div className={"container"}>
        <Switch>
          {isLoading ? (
           <PageSpinner/>
          ) : null}
          {isAuthenticated ? (
            <Route path="/admin" component={Admin} />
          ) : (
            null
          )}
          <Route
            path={"/"}
            exact={true}
            render={() =>
              isAuthenticated ? <Redirect to="/admin/home" /> :  
              null
            }
          />
        </Switch>
      </div>
    </div>
  );
}
export default App;
