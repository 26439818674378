import React from 'react';
import ReactDOM from 'react-dom';
// import { createBrowserHistory } from 'history';
import App from "./App"
import { Auth0Provider } from "./auth/auth0-context";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";

import 'assets/css/material-dashboard-react.css?v=1.6.0';

ReactDOM.render(
// tslint:disable-next-line: jsx-wrap-multiline
<Auth0Provider>
  {/* <Router history={hist}> */}
  <BrowserRouter>
    <App/>
    </BrowserRouter>
  {/* </Router> */}
  </Auth0Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();