import React from 'react';
import { PropagateLoader } from "react-spinners";

export default function Spinner() {
  return (
    <div style={{ marginLeft: "50%", marginRight: "-50%" }}>
      <PropagateLoader />
    </div>
  );
}
