import React from "react";
import { useAuth0 } from "../../auth/auth0-context";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
// import Avatar from "@material-ui/core/Avatar";
// core components
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
// import CardAvatar from "../../components/Card/CardAvatar";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";

import { createStyles } from "@material-ui/core";

const styles = createStyles({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: 300,
    fontFamily:
      "Poppins, sofia-pro, 'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
});

function UserProfile(props: any) {
  const { classes } = props;
  const { user } = useAuth0();
  return (
    <div>
      <GridContainer>
        {/* <GridItem xs={12} sm={12} md={8}> */}
        <Card>
          <CardHeader color="white">
            <h3 className={classes.cardTitleBlack}>Edit Profile</h3>
            <h5 className={classes.cardCategoryBlack}>Complete your profile</h5>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={5}>
                <CustomInput
                  labelText="Company (disabled)"
                  id="company-disabled"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    disabled: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                  labelText="Username"
                  id="username"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <CustomInput
                  labelText="Email address"
                  // labelText={user.name}
                  value={user.name}
                  id="email-address"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="First Name"
                  id="first-name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Last Name"
                  id="last-name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <CustomInput
                  labelText="City"
                  id="city"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <CustomInput
                  labelText="Country"
                  id="country"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <InputLabel style={{ color: "#AAAAAA" }}>About me</InputLabel>
                <CustomInput
                  labelText=""
                  id="about-me"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    multiline: true,
                    rows: 5,
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
          <CardFooter>
            <Button color="logoutButton">Update Profile</Button>
          </CardFooter>
        </Card>
        {/* </GridItem> */}
        {/*  <GridItem xs={12} sm={12} md={4}>
          <Card profile={true}>
            <CardAvatar profile={true}>
              <a href="#pablo" onClick={(e) => e.preventDefault()}>
                <Avatar
                  style={{ height: "6em", width: "6em", fontSize: "20px" }}
                >
                  {user.name[0].toUpperCase()}
                </Avatar>
              </a>
            </CardAvatar>
            <CardBody profile={true}>
              <h6 className={classes.cardCategory}>{user.name}</h6>
              <h4 className={classes.cardTitle}>Lead Designer</h4>
              <p className={classes.description}>
                Some description here 
              </p>
            </CardBody>
          </Card>
        </GridItem> */}
      </GridContainer>
    </div>
  );
}

export default withStyles(styles)(UserProfile);
