import {
  successColor,
  whiteColor,
  grayColor,
  blackColor
} from '../../material-dashboard-react';
import { createStyles } from '@material-ui/core';

const dashboardStyle = createStyles({
  successText: {
    color: successColor[0],
  },
  upArrowCardCategory: {
    width: "16px",
    height: "16px",
  },
  stats: {
    color: grayColor[0],
    display: "inline-flex",
    fontSize: "12px",
    lineHeight: "22px",
    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
  },
  cardCategory: {
    color: grayColor[0],
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    paddingTop: "10px",
    marginBottom: "0",
  },
  cardCategoryBlack: {
    color: "black",
    margin: "0",
    fontSize: "18px",
    marginTop: "revert",
    marginBottom: "0",
    textAlign: "center",
    fontWeight: 900,
    lineHeight: "27px",
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: 300,
    fontFamily: "Poppins, sofia-pro 'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    textAlign: "center",
    "& small": {
      color: grayColor[1],
      fontWeight: 400,
      lineHeight: 1,
    },
  },
  cardTitleWhite: {
    color: whiteColor,
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: 300,
    fontFamily: "Poppins,sofia-pro, 'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: 400,
      lineHeight: 1,
    },
  },
  cardTitleGray: {
    color: "black",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: 300,
    fontFamily: "Poppins,sofia-pro, Roboto, Helvetica, Arial, sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: 400,
      lineHeight: 1,
    },
  },
  cardTitleBlack: {
    color: blackColor,
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: 550,
    fontStyle: "normal",
    fontSize: "40px",
    fontFamily: "Poppins,sofia-pro, Roboto, Helvetica, Arial, sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    textAlign: "center",
    lineHeight: "45px",
    "& small": {
      color: blackColor,
      fontWeight: 400,
      lineHeight: 1,
    },
  },
});

export default dashboardStyle;
