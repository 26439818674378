import {
  container,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  // grayColor,
  blackColor,
} from "../../material-dashboard-react";
import { createStyles, Theme } from "@material-ui/core";

const headerStyle = (theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: "transparent",
      boxShadow: "none",
      borderBottom: "0",
      marginBottom: "0",
      position: "absolute",
      width: "100%",
      paddingTop: "10px",
      zIndex: 1029,
      color: blackColor,
      border: "0",
      borderRadius: "3px",
      padding: "10px 0",
      transition: "all 150ms ease 0s",
      minHeight: "50px",
      display: "block",
      // backdropFilter: 'blur(1.7px)',
    },
    container: {
      ...container,
      minHeight: "50px",
    },
    flex: {
      flex: 1,
    },
    title: {
      ...defaultFont,
      lineHeight: "30px",
      fontSize: "15px",
      borderRadius: "3px",
      textTransform: "capitalize",
      fontWeight: 500,
      color: blackColor,
      margin: "0",
      "&:hover,&:focus": {
        background: "transparent",
      },
    },
    appResponsive: {
      top: "8px",
    },
    primary: {
      backgroundColor: primaryColor[0],
      color: whiteColor,
      ...defaultBoxShadow,
    },
    info: {
      backgroundColor: infoColor[0],
      color: whiteColor,
      ...defaultBoxShadow,
    },
    success: {
      backgroundColor: successColor[0],
      color: whiteColor,
      ...defaultBoxShadow,
    },
    warning: {
      backgroundColor: warningColor[0],
      color: whiteColor,
      ...defaultBoxShadow,
    },
    danger: {
      backgroundColor: dangerColor[0],
      color: whiteColor,
      ...defaultBoxShadow,
    },
    dropDownText: {
      color: "#000",
      margin: "0",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "30px",
      padding: "0 auto",
    },
    dropDownLink: {
      paddingBottom: "0",
      paddingTop: "0",
    },
  });

export default headerStyle;
