import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Search from "@material-ui/icons/Search";

// core components
import CustomInput from "../CustomInput/CustomInput";
import Button from "../CustomButtons/Button";

import headerLinksStyle from "../../assets/jss/material-dashboard-react/components/headerLinksStyle";

interface Props {
  classes: any;
}

class HeaderLinks extends React.Component<Props, {}> {
  anchorEl: any;

  state = {
    open: false,
  };

  handleToggle = () => {
    this.setState({ open: !this.state.open });
  };

  handleClose = (event: any) => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.searchWrapper}>
          <CustomInput
            formControlProps={{
              className: classes.margin + " " + classes.search,
            }}
            inputProps={{
              placeholder: "Search",
              inputProps: {
                "aria-label": "Search",
              },
            }}
          />

          <Button color="white" aria-label="edit" justIcon={true} round={true}>
            <Search />
          </Button>
        </div>
        <div className={classes.manager} />
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(HeaderLinks);
