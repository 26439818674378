import React from 'react'
import { PropagateLoader } from "react-spinners";
import styled from "styled-components";

const SpinnerContainer = styled.div`
margin-left: 50%;
margin-right: 50%;
margin-top: 20%;
`;

export default function Spinner() {
  return (
    <SpinnerContainer>
      <PropagateLoader />
    </SpinnerContainer>
  );
}
